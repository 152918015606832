.Sidenav {
  width: 280px;
  background-color: white;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: left 200ms, background-color 250ms, box-shadow 250ms;
  z-index: 1;
  border-right: 1px solid rgb(234, 234, 239);

  .close-btn {
    position: absolute;
    left: 15px;
    top: 15px;
    display: none;
  }

  &.open {
    left: 0px;
  }

  &.closed {
    left: -281px;
  }

  .divider {
    margin-top: 2rem;
    margin-bottom: 4rem;
    width: 200px;
  }

  .nav-button {
    width: 100%;
    height: 100%;
    padding: 0.75rem 1.25rem;
    border-radius: 8px;
    justify-content: flex-start;
  }

  body.dark {
    .nav-button {}
  }

  .engagement-header {
    display: none;
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    .divider {
      margin-top: 1.25rem;
      margin-bottom: 3rem;
    }


    .engagement-header {
      display: block;
    }
  }
}

body.dark {
  .Sidenav {
    background-color: var(--colors-body-background-dark);
    box-shadow: rgba(5, 5, 5, 0.35) 1px 1px 10px;
    border-right: none;

    .menu li {
      color: #b7b7b7;
    }
  }

  .Sidenav .menu li:hover,
  .Sidenav .menu .active li {
    background-color: rgba(var(--colors-primary-rgb), 0.1);
    color: white !important;
  }

  .Sidenav .menu li:hover svg,
  .Sidenav .menu .active svg {
    color: var(--colors-primary);
  }
}

.Sidenav .container {
  padding: 30px 15px 40px 15px;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.Sidenav .menu {
  width: 100%;
}

.Sidenav .menu ul {
  list-style: none;
  padding: 0;
  margin-top: 0;
}

.Sidenav .menu li:first-child {
  margin-top: 0;
}

.Sidenav .menu li {
  margin: 8px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 200ms;
  color: #5f748d;
}

.Sidenav .menu svg {
  color: #cbced4;
  margin-right: 15px;
  transition: all 200ms;
}

.Sidenav .menu li:hover,
.Sidenav .menu .active li {
  background-color: rgba(var(--colors-primary-rgb), 0.1);
  color: var(--colors-primary);
}

.Sidenav .menu li:hover svg,
.Sidenav .menu .active svg {
  color: var(--colors-primary);
}

.Sidenav .buttons {
  width: 85%;
  margin-bottom: 5px;
}

.Sidenav a:visited,
.Sidenav a {
  color: inherit;
}

@media screen and (max-width: 900px) {
  .Sidenav {
    width: 100%;

    &.closed {
      left: -100%;
    }

    .close-btn {
      display: block;
    }
  }
}