.tasks-table .MuiCheckbox-root {
  padding: 0;
}

.tasks-table .MuiTableRow-root {
  transition: 250ms all;
  cursor: pointer;
}

.filter-sort-buttons span.MuiTypography-root {
  font-size: 0.92rem;
}

.tasks-table .selected {
  background-color: rgba(var(--colors-primary-rgb), 0.075) !important;
}

.tasks-table .selected::after {
  top: 0px;
  left: 0px;
  width: 3px;
  content: "";
  height: 100%;
  position: absolute;
  background-color: var(--colors-primary);
}

.tasks-table thead th {
  padding-bottom: 1.5rem;
}

.tasks-filter-accordion {
  &.MuiPaper-root {
    padding: 0 !important;
    box-shadow: none !important;
  }

  .accordion-summary {
    justify-content: flex-start;

    & .MuiAccordionSummary-content {
      flex-grow: 0;
    }
  }
}