.header {
  .org-circle::after {
    content: '';
    height: 8px;
    width: 8px;
    background: #43e335;
    position: absolute;
    display: block;
    bottom: 11px;
    left: 25px;
    border-radius: 50%;
  }

  .header-box {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header-button {
    padding: 0;
    border-radius: 50%;
  }

  .engagement-title {
    padding: 0 8px;
    flex-grow: 1;
    margin-left: 2rem;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }
}