.Home main {
  background-color: #fafafa;
  transition: margin 200ms, background-color 250ms, color 250ms;
  padding: 0 2.5rem;
  min-height: 100vh;

  .content {
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
  }
}

body.dark .Home main {
  color: white;
  background-color: var(--colors-body-background-dark);
}

.custom-tab {
  padding: 0 !important;
  border-radius: 24px !important;
  transition: background 200ms linear !important;
  background: white;

  .tab-btn {
    padding: 10px 15px;
    border-radius: 24px;
    transition: color 200ms linear;
    color: var(--colors-primary);
  }
}

body.dark .custom-tab {
  background: #222222;
}

.custom-tab.active {
  background: var(--colors-primary) !important;

  .tab-btn {
    color: white;
  }
}

.settings-nav {
  .title {
    font-size: .9rem;
    color: #9b9b9b;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
    font-weight: 300;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      color: #5e5e5e;
      padding-left: 8px;

      &:hover {
        text-decoration: underline;
      }

      svg {
        color: #a1a1a1;
        margin-right: 12px;
        font-size: 22px;
      }

      .active svg {
        color: var(--colors-primary);
      }

      a {
        color: unset;
        display: flex;
        align-items: center;

        &.active {
          color: var(--colors-primary);
          text-decoration: underline;
        }
      }
    }
  }
}