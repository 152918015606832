.permissions-dialog {
  min-width: 800px;

  @media screen and (max-width: 800px) {
    min-width: unset;
    width: 100%;

    .content {
      padding: 0;
    }
  }
}

.search-dialog {
  max-height: 500px !important;
  width: 500px;
}

.invite-dialog {

  .invite-types {
    display: flex;
    align-items: center;
    gap: 2rem;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
  }

  .invite-type {
    flex-grow: 1;
    width: 250px;
    text-align: center;
    box-shadow: rgb(0 0 0 / 15%) 0px 7px 24px 0px !important;
    border: 2px solid transparent;
    transition: border 500ms;

    &.selected {
      border: 2px solid var(--colors-primary);
    }
  }
}