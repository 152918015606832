.in-progress-icon {
  background-color: #ffa5001c;
  color: orange;
  display: inline-flex;
  border-radius: 50%;
  padding: 15px;
}

.completed-icon {
  background-color: #27ce881a;
  color: #27ce88;
  display: inline-flex;
  border-radius: 50%;
  padding: 15px;
}

.past-due-icon {
  background-color: #ff00000f;
  color: red;
  display: inline-flex;
  border-radius: 50%;
  padding: 15px;
}

.task-button {
  white-space: break-spaces !important;
  text-align: left;
  width: 100%;
}