.Login {
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  justify-content: center;

  header {
    top: 0;
    height: auto;
    position: sticky;
    height: var(--header-height);
    border-bottom: 1px solid rgba(0, 0, 0, .08);
    display: flex;
    background-color: white;
    z-index: 2;

    .logo {
      height: 1.6rem;
    }

    .inner {
      max-width: 1400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      margin: 16px auto;
      flex-grow: 1;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - var(--header-height));

    h1 {
      font-weight: 200;
      margin-bottom: 2rem;
      text-align: center;
    }

    .form-content {
      padding: 50px 75px;

      @media screen and (max-width: 700px) {
        padding: 35px 25px;
      }
    }

    .inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      form {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}

.VerifyLogin {
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  justify-content: center;

  header {
    top: 0;
    height: auto;
    position: sticky;
    height: var(--header-height);
    border-bottom: 1px solid rgba(0, 0, 0, .08);
    display: flex;
    background-color: white;
    z-index: 2;

    .logo {
      height: 1.6rem;
    }

    .inner {
      max-width: 1400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      margin: 16px auto;
      flex-grow: 1;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - var(--header-height));

    .form-content {
      padding: 50px 75px;

      @media screen and (max-width: 700px) {
        padding: 35px 25px;
      }
    }
  }
}