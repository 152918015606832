.settings-container {

  h1 {
    font-size: 1.5rem;
  }

  .Members {
    .new-invites-container {
      .new-invite-row {
        display: flex;
        gap: 8px;

        & .MuiTypography-root,
        & .MuiInputBase-root {
          flex-basis: 100%;
          margin: 0;
          height: 42px;
        }
      }
    }
  }

  .billing-tab {
    .free-plan-info {
      .MuiAlert-root {
        justify-content: start !important;
      }
    }

    .payment-content {
      display: flex;
      margin-top: 4rem;
      gap: 3rem;
      justify-content: space-evenly;


      @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        align-items: center;
      }

      form {
        flex-basis: 50%;
      }

      .payment-details {
        width: 450px;
        padding: 0;
        overflow: hidden;

        @media screen and (max-width: 550px) {
          width: auto;
        }

        .header {
          color: white;
          background-color: var(--colors-primary);
          padding: 32px 36px 28px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .currency {
            border: 1px solid #b9b9b9;
            border-radius: 4px;
            padding: 8px 22px;
          }
        }
      }
    }

    .payment-success {
      .MuiAlert-root {
        justify-content: start !important;
      }
    }
  }
}